(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-settings-selector/assets/javascripts/coupon-settings-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-settings-selector/assets/javascripts/coupon-settings-selector.js');
"use strict";

const {
  Fieldset
} = svs.ui.reactForm;
const CouponSettingsSelector = _ref => {
  let {
    children
  } = _ref;
  return React.createElement(Fieldset, {
    className: "pg_coupon_settings__item"
  }, children);
};
setGlobal('svs.components.tipsen.couponSettingsSelector.CouponSettingsSelector', CouponSettingsSelector);

 })(window);