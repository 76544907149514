(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-settings-selector/assets/javascripts/components/coupon-settings-heading.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-settings-selector/assets/javascripts/components/coupon-settings-heading.js');
"use strict";

const {
  CouponSettingsSelector
} = svs.components.tipsen.couponSettingsSelector;
const CouponSettingsSelectorHeading = _ref => {
  let {
    children
  } = _ref;
  return React.createElement("legend", {
    className: "pg_coupon_settings__heading"
  }, children);
};
CouponSettingsSelector.CouponSettingsHeading = CouponSettingsSelectorHeading;

 })(window);