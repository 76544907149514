(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-settings-selector/assets/javascripts/components/coupon-settings-buttons.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-settings-selector/assets/javascripts/components/coupon-settings-buttons.js');
"use strict";

const {
  CouponSettingsSelector
} = svs.components.tipsen.couponSettingsSelector;
const {
  RadioGroup
} = svs.ui.reactForm;
const {
  clx
} = svs.components.lbUtils;
const CouponSettingsSelectorButtons = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement(RadioGroup, {
    className: clx('pg_coupon_settings__btns', className),
    size: "200",
    thin: true
  }, children);
};
CouponSettingsSelector.CouponSettingsButtons = CouponSettingsSelectorButtons;

 })(window);